import { api, getPlaceholder, DEFAULT } from '../utils'
import { PAGINEDETAIL, REDIRECTS } from '../utils/endpoint'
import React, { useEffect, useState } from 'react'
import LoadingSvg from '../components/atoms/Loading/Loading'
import Error from 'next/error'
import { MetaSeo } from '../components/index'
import Breadcrumbs from '../components/molecules/Breadcrumbs/Breadcrumbs'
import Placeholder from '../components/atoms/Placeholder/Placeholder'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const Page = ({ page, statusCode }) => {
  if (!page) return <Error statusCode={statusCode} />

  const router = useRouter()
  const { utente, fetching, fetched } = useSelector((state) => state.utente)
  const [placeholder, setPlaceholder] = useState(null)

  useEffect(() => {
    if (page?.login_required && fetched && !utente) {
      router.replace(`/myaccount/accedi?next=${router.asPath}`)
    }

    if (page?.login_required && !utente) return

    fetched && initializePlaceholder()
  }, [fetched, utente])

  const initializePlaceholder = async () => {
    let content = null
    if (page.template === DEFAULT && fetched) {
      const placeholderParams = {
        key: 'content',
        object_id: page.id,
        lang: router.locale,
        utente,
      }

      content = await getPlaceholder(placeholderParams)
    }

    setPlaceholder(content)
  }

  if (page?.login_required) {
    if (!utente) {
      return <LoadingSvg />
    }
  }

  return (
    <div className="page">
      <MetaSeo seo={page.seo} />
      <div className="w-container">
        <Breadcrumbs breadcrumbs={page.breadcrumbs} />
        <h1 className="page__title">{page.titolo}</h1>
        {/* Div che utilizza Cookiebot per iniettare il contenuto */}
        {page.chiave === 'cookie-policy' ? (
          <div className="custom-content"></div>
        ) : (
          placeholder && <Placeholder placeholder={placeholder} />
        )}
      </div>
    </div>
  )
}

export const getServerSideProps = async ({ req, res, query, locale }) => {
  const route = '/' + query.path.join('/') // { path: ['magazine', 'architetti'] }
  const headers = req?.headers?.cookie ? { cookie: req.headers.cookie } : {}

  // per evitare che in locale venga gestito media url da frontend
  if (route.includes('media')) {
    return { props: { statusCode: 404 } }
  }

  const response = await api.get(REDIRECTS, { params: { old_path: route } }, { headers })
  const redirects = await response.data

  res.setHeader('Cache-Control', 'public, max-age=120, must-revalidate')

  if (redirects.length) {
    // questo faceva 302 i seo vogliono 301
    // res.redirect(redirects[0].new_path)

    res.writeHead(301, {
      Location: redirects[0].new_path,
    })
    res.end()
  }

  try {
    let params = {
      path: route,
      lang: locale,
    }

    const result = await api.get(PAGINEDETAIL, { params, headers })
    let page = await result.data

    const toolbarProps = { toolbarPage: page }

    return { props: { page, key: page.id, ...toolbarProps } }
  } catch (error) {
    res.statusCode = 404
    return { props: { statusCode: 404 } }
  }
}

export default Page
